<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-data-table class="cebra elevation-2 mt-2" :headers="headers" :items="notificaciones" :loading="load" dense>
        <template v-slot:[`item.estado_nom`]="{ item }">
          <v-chip :color="item.Estado_id === 1 ? 'warning' : 'success'" small>
            {{ item.estado_nom }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon color="info" title="Ver" class="mr-2" small @click="ver(item)">
            fas fa-eye
          </v-icon>
          <v-icon v-if="item.Estado_id === 1" color="success" title="Marcar como leida" small
            @click="marcar_como_leida(item)">
            fas fa-check
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-alert class="mx-auto mt-4" max-width="400" type="warning" border="left" dense text>
            No hay notificaciones
          </v-alert>
        </template>
      </v-data-table>
      <v-row cols="12" class="d-flex justify-end mx-0 my-1">
        <BtnConfirmar nombre="Marcar todas como leidas" icono="fas fa-check"
          texto="¿Desea marcar todas las notificaciones pendientes como leidas?" clase="mt-2 ml-2"
          :disabled="load || (notificaciones.filter(notif => notif.Estado_id === 1).length < 1)" @action="marcar_todas" />
        <v-btn color="info" class="mt-2 ml-2" :loading="load" @click="get_notificaciones">
          <v-icon left>fas fa-sync-alt</v-icon>
          Refrescar
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import BtnConfirmar from '../components/util/BtnConfirmar'
import moment from 'moment'
export default {
  data() {
    return {
      load: false,
      notificaciones: [],
      headers: [
        { text: 'Código', value: 'Id' },
        { text: 'Fecha', value: 'Fecha' },
        { text: 'Título', value: 'Titulo' },
        { text: 'Estado', value: 'estado_nom' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
    }
  },
  created() {
    this.get_notificaciones()
  },
  components: {
    BtnConfirmar,
  },
  methods: {
    async ver(item) {
      // muestra el mensaje de la notificacion en un modal
      this.$swal.fire({
        icon: 'info',
        title: item.Titulo,
        text: item.Mensaje,
      })
        .then(async () => {
          // si la notificacion esta pendiente la marca como leida
          if (item.Estado_id === 1) {
            this.$store.state.loading = true
            await this.marcar_como_leida(item)
            this.$store.state.loading = false
          }
        })
    },


    async marcar_todas() {
      // obtiene solo las no leidas y las marca como leidas
      const no_leidas = this.notificaciones.filter(notif => notif.Estado_id === 1)

      for (let index = 0; index < no_leidas.length; index++) {
        const item = no_leidas[index]
        await this.marcar_como_leida(item)
      }
    },
    async marcar_como_leida(item) {
      this.$store.state.loading = true
      await this.$store.dispatch('marcar_notificacion_leida', { id: item.Id })
        .then(() => {
          // marca la notificacion como leida en el array
          const index = this.notificaciones.indexOf(item)
          this.notificaciones[index].Estado_id = 2
          this.notificaciones[index].estado_nom = 'LEIDA'

          // elimina la notificacion del local storege
          this.$store.commit('delete_notificacion', item)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async get_notificaciones() {
      this.load = true
      this.notificaciones = []
      await this.$store.dispatch('get_notificaciones')
        .then(res => {
          this.notificaciones = [...res.data]
          this.notificaciones.forEach(item => {
            item.Fecha = moment(item.Fecha).format('DD/MM/YYYY');
          });
          this.notificaciones.forEach(obj => {
            if (obj.Estado_id == 1) {
              obj.estado_nom = 'No leido';
            } else {
              obj.estado_nom = 'Leido'
            }
          });
          // actualiza el local storage con las notificaciones no leidas
          this.$store.commit('set_notificaciones', res.data.filter(notif => notif.Estado_id === 1))
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.load = false
    }
  }
}
</script>